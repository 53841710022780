<template>
	<div class="menu">
		<ul class="menu__list" v-if="links?.length > 0">
			<li v-for="(item, index) in links" :key="index" class="menu__item">
				<component
          :is="item.attrs.to ? 'router-link' : 'a'"
          class="menu__link" v-bind="item.attrs"
          @click="() => changeModeWrapper(item.id)"
        >
					{{ item.text }}
				</component>
			</li>
		</ul>

		<div class="menu__footer">
			<template v-if="$store.getters.isAuth">
				<tk-button class="menu__btn" @click="logout" kind="main-border">
					Выйти
				</tk-button>
			</template>
			<template v-else>
				<tk-button class="menu__btn" @click="register" kind="main-border">
					Регистрация
				</tk-button>
				<tk-button class="menu__btn" @click="login" kind="main-border">
					Войти
				</tk-button>
			</template>
		</div>
	</div>
</template>

<script>
import modals from '@/constants/modals'
import mods from '@/constants/mods'
import { LOGOUT } from '@/constants/store/auth/actions'

export default {
	inject: ['chat'],
	emits: ['close'],
	methods: {
		login() {
			this.$emit('close')
			this.$store.commit('showModal', {
				name: modals.AUTH,
				props: null,
				isShow: true
			})
		},
		register() {
			this.$emit('close')
			this.$store.commit('showModal', {
				name: modals.REGISTRATION,
				props: null,
				isShow: true
			})
		},
		async logout() {
			await this.$store.dispatch(LOGOUT)
			this.chat.disconnect()
		},
    changeModeWrapper(id) {
      if (!id && !(id === 'guest' || id === 'master')) return;
      const isGuest = this.$store.getters.mode === mods.GUEST;
      if ((isGuest && id === 'guest') || (!isGuest && id === 'master')) return;
      this.changeMode();
    },
		changeMode() {
			const isGuest = this.$store.getters.mode === mods.GUEST

			if (isGuest) {
				this.$store.commit('changeMode', mods.MASTER)
			} else {
				this.$store.commit('changeMode', mods.GUEST)
			}

			// setTimeout(() => {
			// 	this.$router.push({ name: isGuest ? 'Owner' : 'Guest' })
			// })
		}
	},
	computed: {
		links() {
			if (this.$store.getters.isAuth) {
				const arr = [
					{
						text: 'Кабинет гостя',
            id: 'guest',
						attrs: {
							to: this.$store.state.user.account_confirmation === 'CONFIRMED' ?
								'/profile/trips'
								: '/profile/anketa'
						}
					},
					{
						text: 'Кабинет хозяина',
            id: 'master',
						attrs: {
							to: this.$store.state.user.account_confirmation === 'CONFIRMED' ?
								'/profile/my-housing'
								: '/profile/anketa'
						}
					},
					{ text: 'Настройки', attrs: { to: '/profile/settings' } },
				]

				if (this.$store.getters.mode === mods.GUEST) {
					arr.push({
						text: 'Избранное',
						attrs: { to: '/favourites' }
					})
				}

				if (this.$store.getters.mode === mods.MASTER) {
					arr.push({
						text: 'Моё жильё',
						attrs: { to: '/profile/my-housing' }
					})
				}

				return arr
			}

			return []
		}
	}
}
</script>

<style lang="sass">
.menu
  width: 260px
  padding: 30px 20px
  background-color: #FFFFFF
  border: 1px solid #EBEBEB
  box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.05)
  border-radius: 20px
  box-sizing: border-box

  &__list
    margin: 0
    padding: 0

    list-style: none

  &__item
    margin-bottom: 20px

    &:last-of-type
      margin-bottom: 0

  &__link
    display: flex
    align-items: center
    justify-content: space-between
    font-size: 16px
    font-weight: 500
    color: #2A3043
    line-height: 19px
    transition: 0.3s
    text-decoration: none

    &:hover
      padding-left: 5px

    &:after
      display: inline-block
      content: ''
      width: 4px
      height: 4px
      flex-grow: 0
      flex-shrink: 0
      border-bottom: 1px solid #ddd
      border-right: 1px solid #ddd
      transform: rotateZ(-45deg)
      transition: 0.3s

    &:hover:after
      border-bottom: 1px solid #1AC386
      border-right: 1px solid #1AC386
      transform: rotateZ(-45deg) scale(1.1)

  &__footer
    margin-top: 60px

  &__btn
    width: 220px
    margin-bottom: 10px

    &:last-child
      margin-bottom: 0

  @media screen and (max-width: 768px)
    position: fixed
    left: 0
    width: 100vw
    height: 100%
    box-shadow: unset
    border-radius: unset
    border: 0

    &__footer
      display: flex
      flex-wrap: wrap
      justify-content: space-around
</style>
